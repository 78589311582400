$(function() {

    btn=$('.header__btn');
    navbar=$('.header__links');
    body=$('body');
    btn.click(function(e) {
        e.preventDefault();
        navbar.toggleClass('open');
        btn.toggleClass('open');
        body.toggleClass('overflow');
    });



});

function myMap() {
    var mapOptions = {
        center: new google.maps.LatLng(46.551551,15.677864),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    var map = new google.maps.Map(document.getElementById("map"), mapOptions);
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(46.551551,15.677864),
        map: map
    });
}